<template>
  <div v-if="!!type" class="overlay">
    <div open class="modal" @click.stop>
      <header class="modal-header">
        <h2 class="modal-header__title">
          {{ $t(modalTitle) }}
        </h2>
        <icon-button
          variant="close"
          class="modal-header__close"
          @click="closeModal"
          :style="iconStyle"
        />
      </header>
      <ul class="modal-body">
        <li class="modal-body__list">
          <label for="task-title">
            {{ $t("tasks.modal.addTask.taskTitle") }} *
          </label>
          <input
            required
            :disabled="isViewMode"
            v-model="currentTask.title"
            type="text"
            id="task-title"
            :placeholder="$t('tasks.modal.addTask.taskTitleEmpty')"
          />
        </li>
        <li class="modal-body__list">
          <label for="task-description">
            {{ $t("tasks.modal.addTask.taskDescription") }}
          </label>
          <textarea
            required
            :disabled="isViewMode"
            v-model="currentTask.description"
            id="task-description"
            :placeholder="$t('tasks.modal.addTask.taskDescriptionEmpty')"
          />
        </li>
        <li class="modal-body__list">
          <label class="ui-label">
            {{ $t("tasks.modal.addTask.dueDate") }} *
          </label>
          <vue-date-picker
            class="datepicker"
            locale="ru"
            teleport-center
            @update:model-value="updateDueDate"
            :dark="$themeStore.isDarkTheme"
            :enable-time-picker="false"
            v-model="currentTask.dueDate"
            :placeholder="$t('tasks.modal.addTask.dueDateEmpty')"
            required
            :month-change-on-scroll="false"
          />
        </li>
        <li class="modal-body__list">
          <label for="priority-select">
            {{ $t("tasks.modal.addTask.selectPriority") }} *
          </label>
          <select
            id="priority-select"
            required
            v-model="currentTask.priority"
            :disabled="isViewMode"
          >
            <option disabled value="0">
              {{ $t("tasks.modal.addTask.selectPriorityEmpty") }}
            </option>
            <option
              v-for="priority in modalContent.priorities"
              :key="priority.value"
              :value="priority.value"
            >
              {{ $t(`tasks.modal.addTask.priorities.${priority.label}`) }}
            </option>
          </select>
        </li>
        <li v-if="Number($userStore.roleId) === 1" class="modal-body__list">
          <label class="ui-label">
            {{ $t("tasks.modal.addTask.selectUser") }}
          </label>
          <multi-select-wr
            v-model="currentTask.assignees"
            :placeholder="$t('tasks.modal.addTask.selectUser')"
            label="name"
            track-by="id"
            :options="users"
            multiple
            searchable
            @select="selectAssignees"
            @remove="removeAssignee"
            required
          />
        </li>
        <li class="modal-body__list">
          <label class="ui-label">
            {{ $t("tasks.modal.addTask.selectWatcher") }}
          </label>
          <multi-select-wr
            v-model="currentTask.watchers"
            :placeholder="$t('tasks.modal.addTask.selectWatcher')"
            label="name"
            track-by="id"
            :options="users"
            multiple
            searchable
            @select="selectWatchers"
            @remove="removeWatcher"
            required
          />
        </li>
      </ul>
      <footer class="modal-footer">
        <button
          v-if="!isViewMode"
          class="modal-footer__submit"
          @click="handleSubmit"
          :disabled="!isFormValid || isLoading"
        >
          <span v-if="isLoading">
            {{ $t("tasks.modal.addTask.actionProcessing") }}
          </span>
          <span v-else>
            {{
              isEditing
                ? $t("tasks.modal.addTask.actionEdit")
                : $t("tasks.modal.addTask.action")
            }}
          </span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import MultiSelectWr from "@/global-components/MultiSelectWr.vue";
import { TASK_CONTENT } from "../constants/TaskContent";
import IconButton from "./IconButton.vue";

export default {
  components: { IconButton, MultiSelectWr },
  props: {
    date: {
      type: String,
      default: "",
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    users: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTask: {
        title: "",
        description: "",
        priority: 0,
        assignees: [],
        watchers: [],
        dueDate: undefined,
      },
      modalContent: TASK_CONTENT,
      isLoading: false,
      isEditing: false,
      isView: false,
    };
  },
  watch: {
    task: {
      immediate: true,
      handler(newTask) {
        if (newTask && Object.keys(newTask).length) {
          this.currentTask = { ...newTask, priority: newTask.priority.value };
        }
      },
    },
    type: {
      immediate: true,
      handler(newType) {
        if (newType === "edit") {
          this.isEditing = true;
          this.isView = false;
        } else if (newType === "view") {
          this.isEditing = false;
          this.isView = true;
        } else if (newType === "create") {
          this.isEditing = false;
          this.isView = false;
        }
      },
    },
    date: {
      immediate: true,
      handler(newDate) {
        if (!newDate) return;
        if (newDate.includes("T")) {
          this.currentTask.dueDate = new Date(newDate);
          return;
        }
        console.log({ newDate });
        const [, datePart] = newDate.split(", ");
        const [day, month, year] = datePart.split(".").map(Number);
        const onlyDate = new Date(year, month - 1, day);
        onlyDate.setHours(1, 0, 0, 0);
        console.log({ newDate, onlyDate });
        this.currentTask.dueDate = onlyDate;
      },
    },
  },
  computed: {
    isFormValid() {
      return (
        this.currentTask.title &&
        this.currentTask.priority &&
        this.currentTask.dueDate
      );
    },
    iconStyle() {
      return this.$themeStore.isDarkTheme ? { filter: "invert(1)" } : {};
    },
    isViewMode() {
      return this.type === "view";
    },
    modalTitle() {
      if (this.isView) return "tasks.modal.viewTask.title";
      else if (this.isEditing) return "tasks.modal.editTask.title";
      return "tasks.modal.addTask.title";
    },
  },
  mounted() {
    if (Number(this.$userStore.roleId) !== 1) {
      this.currentTask.userId = this.$userStore.userId;
    }
  },
  methods: {
    closeModal() {
      if (this.isLoading) return;
      this.$emit("close");
      this.resetForm();
    },
    selectedWatchers(watchers) {
      this.currentTask.watchers = watchers;
      console.log(watchers);
    },
    selectAssignes(assignees) {
      this.currentTask.assignees = assignees;
      console.log(assignees);
    },
    removeAssignee(assignee) {
      this.currentTask.assignees = this.currentTask.assignees.filter(
        (a) => a.id !== assignee.id
      );
    },
    updateDueDate(dates) {
      this.currentTask.dueDate = dates;
    },
    resetForm() {
      this.currentTask = {
        title: "",
        description: "",
        priority: 0,
        assignees: [],
        watchers: [],
      };
      this.isEditing = false;
      this.isView = false;
    },
    handleSubmit() {
      this.isLoading = true;
      const eventType = this.isEditing ? "update-task" : "add-task";
      this.$emit(eventType, { task: this.currentTask });
      this.closeModal();
      this.resetForm();
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./ModalComponent.scss" />
